import React from "react";
import PropTypes from "prop-types";
import AffiliateLink from "./AffiliateLink";

export default function TableBlock({ tableData }) {
  const { header, data } = tableData;
  if (!tableData || !header || !data) return null;
  const indexOfLink = header.indexOf("Link");

  return (
    <div className="mb-8 lg:mb-16">
      <div className=" overflow-y-auto scrollbar-w-2 scrollbar-track-gray-lighter scrollbar-thumb-rounded scrollbar-thumb-gray scrolling-touch">
        <table className="w-full text-left table-auto">
          <thead>
            <tr>
              {header.map(headerItem => (
                <th className="text-sm font-semibold text-gray-700 px-3 py-2 bg-gray-100" key={headerItem}>
                  {headerItem}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="align-baseline">
            {data.map(dataRow => (
              <tr key={dataRow[0]}>
                {header.map((accessor, i) => (
                  <td key={accessor} className="px-3 py-2 border-t border-gray-300 text-xs text-gray-700">
                    {indexOfLink === i ? <AffiliateLink href={dataRow[i]} /> : dataRow[i]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
TableBlock.propTypes = {
  tableData: PropTypes.object,
};
TableBlock.defaultProps = {
  tableData: {},
};
