const trackEvent = (eventAction, eventNoun) => {
  if (!window.ga && typeof ga !== "function") {
    console.log("GA is not defined for event tracking");
    return;
  }

  try {
    window.ga("send", "event", eventNoun, eventAction);
  } catch (error) {
    console.error(error);
  }
};

export default trackEvent;
