import React from "react";
import PropTypes from "prop-types";
import { formatDateFromTimestamp, toISOFromTimestampInSeconds } from "../common/dateFunctions";

export default function CreatedModifiedAt({ createdAt, modifiedAt }) {
  return (
    <div className="text-sm text-gray-700">
      Published:{" "}
      {createdAt && (
        <time dateTime={toISOFromTimestampInSeconds(createdAt)}>
          {formatDateFromTimestamp(createdAt, { format: "MMM D, YYYY" })}
        </time>
      )}
      <span className="text-lg px-1">·</span>
      Updated:{" "}
      {modifiedAt && (
        <time dateTime={toISOFromTimestampInSeconds(modifiedAt)}>
          {formatDateFromTimestamp(modifiedAt, { format: "MMM D, YYYY" })}
        </time>
      )}
    </div>
  );
}

CreatedModifiedAt.propTypes = {
  createdAt: PropTypes.number.isRequired,
  modifiedAt: PropTypes.number,
};
CreatedModifiedAt.defaultProps = {
  modifiedAt: null,
};
