import React from "react";
import { toSlug } from "../common/toSlug";
import MainBlock from "./BlockComponents/MainBlock";

export default function DisplayBlocks({ blocks }) {
  return blocks.map((block, i) => {
    let content;

    if (block.type === "mainBlock") {
      content = <MainBlock key={i} {...block} />;
    } else {
      console.warn("There was an unrecognized block.type passed into DisplayBlocks.js", block);
    }

    return (
      <li key={i} data-id={String(1 + i).padStart(2, "0")} id={`${toSlug(block.title)}`} className={"block block-" + (i + 1)}>
        {content}
      </li>
    );
  });
}
