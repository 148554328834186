import React from "react";
import PropTypes from "prop-types";
import btoa from "btoa";
import qsAdd from "qs-add";
import { amazonTrackingId } from "../common/amazonTrackingId";
import Button from "./Button";

export const detectAndTransformAffiliateLink = ({ href, amazonProductId }) => {
  let transformedLink = href;
  const [firstPart, secondPart] = href.split(".");
  if (amazonProductId) {
    transformedLink = encodeURIComponent(
      `https://www.amazon.com/dp/${amazonProductId}/?ref_=ast_sto_dp&tag=${amazonTrackingId}&language=en_US`
    );
  } else if (firstPart === "https://amazon") {
    const newUrl = qsAdd(`tag=${amazonTrackingId}`)(href);
    transformedLink = encodeURIComponent(newUrl);
  } else if (firstPart === "https://homedepot") {
    transformedLink = `https://homedepot.sjv.io/c/2079320/459920/8154?u=${encodeURIComponent(href)}`;
  } else if (firstPart === "https://shop" && secondPart === "arborday") {
    transformedLink = `https://www.pntrs.com/t/TUJGRU1IR0JHRUpKTU1CRkhMR0lM?url=${encodeURIComponent(href)}`;
  } else {
    console.warn(`There is an untracked affiliate link on this page: ${href}`);
  }

  return `/out?url=${btoa(transformedLink)}`;
};

export default function AffiliateLink({ isButton, href, amazonProductId }) {
  const trackedAffiliateLink = detectAndTransformAffiliateLink({ href, amazonProductId });

  const reportClick = () => {
    if (typeof ga === "function") {
      window.ga("send", "event", {
        eventCategory: "Affiliate Link Click",
        eventAction: "click",
        eventLabel: href,
      });
    }
  };

  if (isButton) {
    return (
      <Button
        onClick={() => reportClick()}
        color="bg-primary hover:bg-primary-dark text-white mb-2"
        href={trackedAffiliateLink}
        name="Check Prices"
        rel="noopener noreferrer nofollow"
      />
    );
  }

  return (
    <a className="underline button text-primary" href={trackedAffiliateLink} onClick={() => reportClick()}>
      Check Price
    </a>
  );
}

AffiliateLink.propTypes = {
  isButton: PropTypes.bool,
  href: PropTypes.string.isRequired,
};
AffiliateLink.defaultProps = {
  isButton: false,
};
