import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

export default function NextAndPrevious({ previousPost, nextPost }) {
  const nextPostLink = nextPost && nextPost.metadata && nextPost.metadata.url;
  const nextPostTitle = nextPost && nextPost.metadata && nextPost.metadata.title;
  const previousPostLink = previousPost && previousPost.metadata && previousPost.metadata.url;
  const previousPostTitle = previousPost && previousPost.metadata && previousPost.metadata.title;

  return (
    <section className="w-full flex flex-wrap w-full mb-4 lg:mb-8">
      <div className="w-full md:w-1/2 b-1 ">
        {previousPostLink && (
          <Link to={`${previousPostLink}`}>
            {"<< "}Previous
            <div className="text-lg font-bold">{previousPostTitle}</div>
          </Link>
        )}
      </div>
      <div className="w-full md:w-1/2 md:text-right">
        {nextPostLink && (
          <Link to={`${nextPostLink}`}>
            Next {" >>"}
            <div className="text-lg font-bold">{nextPostTitle}</div>
          </Link>
        )}
      </div>
    </section>
  );
}
NextAndPrevious.propTypes = {
  previousPost: PropTypes.any,
  nextPost: PropTypes.any,
};
NextAndPrevious.defaultProps = {
  previousPost: null,
  nextPost: null,
};
