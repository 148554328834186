import React from "react";
import Image from "gatsby-image";
import AffiliateLink, { detectAndTransformAffiliateLink } from "../AffiliateLink";
import AmazonLink from "../AmazonLink";
import AmazonImage from "./AmazonImage";

// eslint-disable-next-line
export default function MainBlock({ title, text, amazonProductId, gatsbyFixedImage, buttonLink, youtubeIframe }) {
  const trackedAffiliateLink = detectAndTransformAffiliateLink({ href: buttonLink });

  return (
    <div className="block-content">
      <header className="block__header">
        <h2>{title}</h2>
      </header>

      {gatsbyFixedImage && (
        <a className="zoom-image" href={trackedAffiliateLink} title={title} rel="noopener noreferrer">
          <Image alt={title} className="w-full max-w-full" fixed={gatsbyFixedImage} />
        </a>
      )}

      {amazonProductId && (
        <AmazonImage
          className="zoom-image"
          imageClassName="w-1/2 md:w-1/4"
          amazonProductId={amazonProductId}
          altText={title}
        />
      )}

      <div className="pt-4 wysiwig" dangerouslySetInnerHTML={{ __html: text }} />
      <div className="pt-2" dangerouslySetInnerHTML={{ __html: youtubeIframe }} />
      {buttonLink && (
        <div className="mt-6">
          <AffiliateLink isButton href={buttonLink} amazonProductId={amazonProductId} />
          <AmazonLink href={title} />
        </div>
      )}
    </div>
  );
}
