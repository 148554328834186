import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { toISOFromTimestampInSeconds } from "../../common/dateFunctions";
import { useTheme } from "../../components/ThemeContext";
import { useLogo } from "../../hooks/useLogo";
import { useMetaImage } from "../../hooks/useMetaImage";
import { data } from "../../zeyVenturesData";
import { toSlug } from "../../common/toSlug";

export default function PostSchemaData({ content, createdAt, url, modifiedAt, title, description, imageSourceUrl }) {
  const { theme } = useTheme();
  const logo = useLogo();
  const metaImage = useMetaImage();

  const structuredData = {
    "@context": "https://schema.org/",
    "@type": "BlogPosting",
    datePublished: toISOFromTimestampInSeconds(createdAt),
    ...(modifiedAt ? { dateModified: toISOFromTimestampInSeconds(modifiedAt) } : {}),
    "@id": `${theme.urlWithProtocol}${url}`,
    headline: title,
    description: description || theme.meta.description,
    author: { "@type": "Person", name: theme.author.name },
    image: { "@type": "ImageObject", url: imageSourceUrl || metaImage.src },
    mainEntityOfPage: { "@type": "WebPage", "@id": theme.urlWithProtocol },
    publisher: {
      "@type": "Organization",
      name: data.businessName,
      url: data.zeyVenturesUrl,
      logo: { "@type": "ImageObject", url: `${theme.urlWithProtocol}${logo.src}` },
    },
    about: [
      {
        "@context": "http://schema.org",
        "@type": "ItemList",
        name: title,
        description: description || theme.meta.description,
        itemListOrder: "http://schema.org/ItemListOrderAscending",
        numberOfItems: 10,
        itemListElement: content.map(item => ({
          "@type": "ListItem",
          position: 1,
          name: item.title,
          url: `${theme.urlWithProtocol}${url}#${toSlug(item.title)}`,
        })),
      },
    ],
  };

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
      </Helmet>
    </>
  );
}
PostSchemaData.propTypes = {
  content: PropTypes.array.isRequired,
  createdAt: PropTypes.number.isRequired,
  modifiedAt: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageSourceUrl: PropTypes.string.isRequired,
};
