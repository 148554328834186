import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Image from "gatsby-image";
import { categoryToSlug } from "../common/toSlug";
import Button from "../components/Button";
import routes from "../routes";
import { formatDateFromTimestamp, toISOFromTimestampInSeconds } from "../common/dateFunctions";
import { useTheme } from "../components/ThemeContext";

export default function BlogCard({ cards, classes }) {
  const {
    theme: { author },
  } = useTheme();
  const { name } = author;
  return cards.map(
    ({
      id,
      metadata: {
        title,
        url,
        createdAt,
        // modifiedAt,
        description,
        topPost,
        // categories,
        primaryCategory,
        // featuredImageUrl,
        processedFeatureImageUrl,
      },
    }) => {
      return (
        <article key={id} className={classes}>
          {processedFeatureImageUrl && processedFeatureImageUrl.src && (
            <Link title={title} className="block h-32 overflow-hidden zoom-image md:h-auto" to={url}>
              <Image alt={title} className="w-full max-w-full" fluid={processedFeatureImageUrl} style={{ height: "250px" }} />
            </Link>
          )}
          <div className="my-3">
            <Button small name={primaryCategory} url={`${categoryToSlug(primaryCategory)}/`}></Button>
            {topPost ? <Button small className="bg-green-400" name="Top Post!" url={`${routes.popular}/`} /> : ""}
          </div>
          <h3 className="mb-3 text-sm font-bold leading-snug md:text-lg hover:underline text-primary-dark ">
            <Link to={url}>{title}</Link>
          </h3>
          <p className="hidden mb-2 text-gray-700 md:block hover:underline">
            <Link to={url}>{description}</Link>
          </p>
          <p className="mb-2 text-xs font-semibold tracking-wide text-gray-600 uppercase">
            <span itemProp="author" itemScope itemType="http://schema.org/Person">
              <span itemProp="name">{name}</span>
            </span>{" "}
            <span className="px-1 text-lg">·</span>{" "}
            <time dateTime={toISOFromTimestampInSeconds(createdAt)}>
              {formatDateFromTimestamp(createdAt, { format: "MMM D, YYYY" })}
            </time>
          </p>
        </article>
      );
    }
  );
}
BlogCard.propTypes = {
  cards: PropTypes.any.isRequired,
  classes: PropTypes.string.isRequired,
};
