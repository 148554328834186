import React from "react";
import PropTypes from "prop-types";
import trackEvent from "../functions/trackEvent";
import { amazonTrackingId } from "../common/amazonTrackingId";
import Button from "./Button";

export default function AmazonLink({ href }) {
  return (
    <>
      <Button
        onClick={() => trackEvent("Click", "Amazon Link")}
        color="bg-primary hover:bg-primary-dark text-white"
        href={`https://www.amazon.com/gp/search?ie=UTF8&tag=${amazonTrackingId}&linkCode=ur2&linkId=c1c4797ee396887b396e386a22df6555&camp=1789&creative=9325&keywords=${href}`}
        name="Check Amazon"
        rel="noopener noreferrer nofollow"
        target="_blank"
      />
      <img
        src={`//ir-na.amazon-adsystem.com/e/ir?t=${amazonTrackingId}&l=ur2&o=1`}
        width="1"
        height="1"
        alt=""
        style={{ border: "none", margin: "0px" }}
      />
    </>
  );
}

AmazonLink.propTypes = {
  href: PropTypes.string.isRequired,
};
