import React from "react";
import PropTypes from "prop-types";
import { toSlug } from "../common/toSlug";

export default function TableOfContents({ blocks }) {
  return (
    <div className="mb-8 lg:mb-16">
      <h4 className="mb-4 text-lg font-semibold">Table of contents</h4>
      <ol>
        {blocks.map(({ title }, i) => (
          <li key={title} data-id={String(1 + i).padStart(2, "0")}>
            <a className="underline hover:text-primary-dark" href={`#${toSlug(title)}`}>
              {title}
            </a>
          </li>
        ))}
      </ol>
    </div>
  );
}
TableOfContents.propTypes = { blocks: PropTypes.any.isRequired };
