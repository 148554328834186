import React from "react";
import PropTypes from "prop-types";
import BlogCard from "./BlogCard";

export default function DisplayPosts({ posts, headline, columns }) {
  let style;
  if (columns === "4") {
    style = "w-1/2 lg:w-1/3 xl:w-1/4 p-2 md:p-3";
  } else if (columns === "3") {
    style = "w-1/2 lg:w-1/2 xl:w-1/3 p-2 md:p-3";
  }
  return (
    <section className="mb-4 lg:mb-8 xl:mb-16">
      <h4 className="text-2xl mb-4">{headline}</h4>
      <div className="flex flex-wrap w-full -mx-2 md:-mx-3">
        <BlogCard classes={style} cards={posts} />
      </div>
    </section>
  );
}
DisplayPosts.propTypes = {
  posts: PropTypes.array,
  headline: PropTypes.string.isRequired,
  columns: PropTypes.string,
};
DisplayPosts.defaultProps = {
  posts: [],
  headline: "",
  columns: "4",
};
