import React from "react";
import { amazonTrackingId } from "../../common/amazonTrackingId";

export default function AmazonImage({ className = "", imageClassName = "", altText, amazonProductId }) {
  return (
    <>
      <a
        className={className}
        href={`https://www.amazon.com/dp/${amazonProductId}/?ref_=ast_sto_dp&tag=${amazonTrackingId}&language=en_US`}
        target="_blank"
        rel="noopener noreferrer"
        title={altText}
      >
        <img
          className={imageClassName}
          alt={altText}
          src={`//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=${amazonProductId}&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=zeyventures-20&language=en_US`}
        />
      </a>
      <img
        src={`https://ir-na.amazon-adsystem.com/e/ir?t=zeyventures-20&language=en_US&l=li2&o=1&a=${amazonProductId}`}
        width="1"
        height="1"
        alt=""
        style={{ border: "none", margin: "0px" }}
      />
    </>
  );
}
