import { useStaticQuery, graphql } from "gatsby";

export const useHeadshot = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(relativePath: { eq: "images/common/headshot.jpg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
            fixed {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  );

  const fluidHeadshot = file && file.childImageSharp && file.childImageSharp.fluid;
  const fixed = file && file.childImageSharp && file.childImageSharp.fixed;

  if (!fluidHeadshot) {
    throw new Error("The theme you are trying to use probably does not have a correct image at common/headshot.jpg");
  }

  return { fluid: fluidHeadshot, fixed };
};
