import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import { useTheme } from "./ThemeContext";
import "./breadCrumbStyles.css";

export default function BreadCrumb({ arrayOfLinkNameValues }) {
  const { theme } = useTheme();

  let baseUrl = theme.urlWithProtocol;
  const itemListElement = arrayOfLinkNameValues.map(({ name, link }, i) => ({
    "@type": "ListItem",
    position: i + 1,
    name,
    item: `${baseUrl}${link}`,
  }));

  const structuredData = { "@context": "https://schema.org/", "@type": "BreadcrumbList", itemListElement };

  return (
    <>
      <ol className="breadcrumbs flex flex-wrap py-2 lg:py-4 text-sm" itemScope itemType="https://schema.org/BreadcrumbList">
        {arrayOfLinkNameValues.map(({ name, link }, i) => (
          <li className="flex-initial" key={link} itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
            <Link itemProp="item" to={`${link}/`}>
              <span itemProp="name"> {name}</span>
            </Link>
            <meta itemProp="position" content={i + 1} />
          </li>
        ))}
      </ol>

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
      </Helmet>
    </>
  );
}
BreadCrumb.propTypes = { arrayOfLinkNameValues: PropTypes.array.isRequired };
