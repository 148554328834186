import React from "react";
import Image from "gatsby-image";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { useTheme } from "../components/ThemeContext";
import { useHeadshot } from "../hooks/useHeadshot";

export default function AuthorByline({ bio, ...restOfProps }) {
  const {
    theme: { author },
  } = useTheme();
  const { name, shortBio } = author;
  const { fixed } = useHeadshot();
  if (bio) {
    return (
      <div className="my-3 lg:my-6" {...restOfProps}>
        <div className="flex flex-wrap items-center mb-2 lg:mb-4">
          <div className="w-auto">
            <Link to="/about/" title="author link">
              <Image alt={`${name} headshot`} className="rounded-full" fixed={fixed} style={{ width: "60px", height: "60px" }} />
            </Link>
          </div>

          <div className="w-3/4 pl-3 lg:pl-4" itemProp="author" itemScope itemType="http://schema.org/Person">
            <Link to="/about/" itemProp="url">
              <h3 className="text-lg font-semibold tracking-wide text-gray-600 uppercase" itemProp="name">
                {name}
              </h3>
            </Link>
          </div>
        </div>
        <div>
          <p>{shortBio}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center my-3 lg:my-6" {...restOfProps}>
      <div className="w-auto">
        <Link to="/about/">
          <Image alt={name} className="rounded-full" fixed={fixed} style={{ width: "60px", height: "60px" }} />
        </Link>
      </div>
      <div className="w-3/4 pl-3" itemProp="author" itemScope itemType="http://schema.org/Person">
        <Link to="/about/" itemProp="url">
          <p className="text-xs font-semibold tracking-wide text-gray-600 uppercase" itemProp="name">
            {name}
          </p>
        </Link>
      </div>
    </div>
  );
}
AuthorByline.propTypes = {
  bio: PropTypes.bool,
};
AuthorByline.defaultProps = {
  bio: false,
};
