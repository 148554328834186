import React from "react";
import PropTypes from "prop-types";
import Image from "gatsby-image";
import routes from "../routes";
import Layout from "../components/Layout";
import SEO from "../components/SeoHelper";
import Sidebar from "../components/Sidebar";
import DisplayBlocks from "../components/DisplayBlocks";
import NextAndPrevious from "../components/NextAndPrevious";
import DisplayPosts from "../components/DisplayPosts";
import TableOfContents from "../components/TableOfContents";
import CreatedModifiedAt from "../components/CreatedModifiedAt";
import PostSchemaData from "../components/PostComponents/PostSchemaData";
import BreadCrumb from "../components/BreadCrumb";
import { categoryToSlug } from "../common/toSlug";
import Container from "../components/Container";
import Button from "../components/Button";
import PageContent from "../components/PageContent";
import { useTheme } from "../components/ThemeContext";
import TableBlock from "../components/TableBlock";
import AuthorByline from "../components/AuthorByline";

function Post({ pageContext }) {
  const { metadata, relatedPosts, nextPost, previousPost, topPosts } = pageContext;
  const {
    primaryCategory,
    processedFeatureImageUrl,
    description,
    introductionParagraph,
    title,
    content,
    tableData,
    imageSourceUrl,
    createdAt,
    modifiedAt,
    url,
    featuredImageAuthorUrl,
    featuredImageAuthorName,
    keyword,
  } = metadata;

  const haveFluidPinterestImage = metadata && metadata.fluidPinterestImage && metadata.fluidPinterestImage.aspectRatio;
  if (haveFluidPinterestImage) console.log("HAVE metadata.processedPinterestImage");

  const { theme } = useTheme();

  return (
    <Layout>
      <SEO isPost title={title} description={description} image={imageSourceUrl} />

      <Container>
        <BreadCrumb
          arrayOfLinkNameValues={[
            { name: "Blog", link: routes.blog },
            { name: primaryCategory, link: categoryToSlug(primaryCategory) },
            { name: title, link: url },
          ]}
        />

        <article className="flex flex-wrap pt-4 lg:pt-8">
          <div className="w-full lg:w-3/4 md:pr-16 lg:pr-24">
            <header className="flex flex-wrap">
              <div className="w-full md:w-1/2 lg:w-5/12">
                {processedFeatureImageUrl && (
                  <>
                    <Image
                      alt={keyword}
                      className="max-w-full"
                      fluid={processedFeatureImageUrl}
                      style={{ width: "380px", height: "380px" }}
                    />
                    <a href={featuredImageAuthorUrl} target="_blank" rel="noopener noreferrer">
                      <p className="py-1 text-xs font-semibold tracking-wide text-gray-600 uppercase">
                        {featuredImageAuthorName && <>PHOTO BY {featuredImageAuthorName}</>}
                      </p>
                    </a>
                  </>
                )}
              </div>
              <div className="w-full pt-4 md:w-1/2 lg:w-7/12 md:pl-8 lg:pt-8">
                <div className="w-full">
                  <Button small name={primaryCategory} url={`${categoryToSlug(primaryCategory)}/`}></Button>
                  <h1 className="my-4 text-xl font-bold leading-tight tracking-normal md:text-3xl lg:text-5xl">{title}</h1>
                  <CreatedModifiedAt createdAt={createdAt} modifiedAt={modifiedAt} />
                  <AuthorByline />
                </div>
              </div>
            </header>

            <section className="pt-4 xl:w-5/6 xl:ml-auto lg:pt-8">
              <PageContent>
                <div className="mb-4 dropcap wysiwig" dangerouslySetInnerHTML={{ __html: introductionParagraph }} />
                <div className="mb-4 text-sm italic text-gray-700 lg:mb-6">
                  This webpage may contain links to products or other sites. Please assume all such links are affiliate links
                  which may result revenue for {theme.url}.
                </div>
                <TableOfContents blocks={content} />
                <TableBlock tableData={tableData} />
                <div className="mb-8 lg:w-3/4 lg:mb-16">
                  {haveFluidPinterestImage && <Image alt={`${title}`} fluid={metadata.fluidPinterestImage} />}
                </div>
                <ol className="blocks">
                  <DisplayBlocks blocks={content} />
                </ol>
                <hr className="w-full my-6 border border-gray-300 border-solid md:my-12" />
                <AuthorByline bio />
              </PageContent>
            </section>
          </div>

          <footer className="w-full md:order-3">
            <NextAndPrevious previousPost={previousPost} nextPost={nextPost} />
            <DisplayPosts posts={relatedPosts} headline="Related Posts" />
            <DisplayPosts posts={topPosts} headline="Top Posts" />
          </footer>
          <div className="w-full lg:w-1/4 md:order-2">
            <Sidebar />
          </div>
          <PostSchemaData
            content={content}
            url={url}
            createdAt={createdAt}
            modifiedAt={modifiedAt}
            title={title}
            description={description}
            imageSourceUrl={imageSourceUrl}
          />
        </article>
      </Container>
    </Layout>
  );
}

Post.propTypes = {
  pageContext: PropTypes.any.isRequired,
};

export default Post;
